
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import OrganisationForm from "@/components/OrganisationForm.vue";
import { OrganisationForm as OrganisationFormType } from "@/@types/OrganisationForm";
import { OMISTUSMUODOT } from "@/constants/omistusmuoto";

export default defineComponent({
    components: { OrganisationForm },
    setup() {
        const store = useStore();
        const userOrg = computed(() => store.state.user.organisation);
        const form: OrganisationFormType = reactive({
            ...userOrg.value,
            omistusmuoto: OMISTUSMUODOT.find((o) => o.id === userOrg.value.omistusmuoto),
        });

        const saveDisabled = computed(() => !form.name);

        const updateOrganisation = () => {
            const mappedForm = {
                ...form,
                omistusmuoto: form.omistusmuoto?.id,
            };
            store.dispatch("saveOrganisation", mappedForm);
        };

        return { form, updateOrganisation, saveDisabled };
    },
});
